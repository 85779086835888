/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'
import type { HydraViewProps } from '~/src/Domain/Shared/Http/HydraView'
import HydraView from '~/src/Domain/Shared/Http/HydraView'
import type { JsonValueObjectProps } from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface HydraCollectionResponseArgs<T extends DomainModelProps>
  extends JsonValueObjectProps {
  '@context': string
  '@id': string
  '@type': string
  totalItems: number | undefined
  view?: HydraViewProps
  member: T[]
}

export default class HydraCollectionResponse<
  X extends DomainModelInterface<X, T>,
  T extends DomainModelProps,
> {
  public readonly '@context': string
  public readonly '@id': string
  public readonly '@type': string
  public readonly 'totalItems': number
  public readonly 'view'?: HydraView
  public readonly 'member': X[] = []

  private constructor(
    context: string,
    id: string,
    type: string,
    totalItems: number,
    view: HydraView | undefined,
    members: X[],
  ) {
    this['@context'] = context
    this['@id'] = id
    this['@type'] = type
    this.totalItems = totalItems
    this.view = view
    this.member = members
  }

  public fromJSON<X extends DomainModelInterface<X, T>, T extends DomainModelProps>(
    values: HydraCollectionResponseArgs<T>,
    fn: (props: T) => X,
  ): HydraCollectionResponse<X, T> {
    return new HydraCollectionResponse(
      values['@context'],
      values['@id'],
      values['@type'],
      values.totalItems ?? values.member.length,
      values.view ? HydraView.prototype.fromJSON(values.view) : undefined,
      values.member.map((i: T) => fn(i)),
    )
  }
}
