/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface HydraViewProps extends JsonValueObjectProps {
  '@id': string
  '@type': string
  first?: string
  last?: string
  next?: string
  previous?: string
}

export default class HydraView implements JsonValueObjectInterface<HydraView, HydraViewProps> {
  public readonly '@id': string
  public readonly '@type': string
  public readonly 'first'?: string
  public readonly 'last'?: string
  public readonly 'next'?: string
  public readonly 'previous'?: string

  private constructor(
    id: string,
    type: string,
    first: string | undefined,
    last: string | undefined,
    next: string | undefined,
    previous: string | undefined,
  ) {
    this['@id'] = id
    this['@type'] = type
    this.first = first
    this.last = last
    this.next = next
    this.previous = previous
  }

  public fromJSON(values: HydraViewProps): HydraView {
    return new HydraView(
      values['@id'],
      values['@type'],
      values.first,
      values.last,
      values.next,
      values.previous,
    )
  }

  public toJSON(): HydraViewProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      first: this.first,
      last: this.last,
      next: this.next,
      previous: this.previous,
    }
  }
}
