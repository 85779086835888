/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import User, { type UserProps } from '~/src/Domain/Identity/User'
import type {
  ChangePasswordPayload,
  ChangeUserPayload,
} from '~/src/Domain/Identity/UserApiRepositoryInterface'
import type UserApiRepositoryInterface from '~/src/Domain/Identity/UserApiRepositoryInterface'
import HydraCollectionResponse, {
  type HydraCollectionResponseArgs,
} from '~/src/Domain/Shared/Http/HydraCollectionResponse'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class UserApiRepository implements UserApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async findUser(): Promise<User> {
    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<UserProps>>('/v1/users'),
      User.prototype.fromJSON,
    )

    if (response.totalItems === 0) {
      throw new Error('Could not fetch user.')
    }

    return response.member[0]
  }

  public async changeUser(user: User, payload: ChangeUserPayload): Promise<void> {
    await this.httpClient.patch(user['@id'].toString(), payload)
  }

  public async changePassword(payload: ChangePasswordPayload): Promise<void> {
    await this.httpClient.post('/v1/change-password', payload)
  }
}
