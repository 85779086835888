/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { saveAs } from 'file-saver'
import type CoaMappingIri from '~/src/Domain/CoaMapping/CoaMappingIri'
import type DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'
import type FileIri from '~/src/Domain/DataRequest/File/FileIri'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import Export, { type ExportProps } from '~/src/Domain/Export/Export'
import type ExportApiRepositoryInterface from '~/src/Domain/Export/ExportApiRepositoryInterface'
import type UserIri from '~/src/Domain/Identity/UserIri'
import type OrganisationIri from '~/src/Domain/Organisation/OrganisationIri'
import HydraCollectionResponse, {
  type HydraCollectionResponseArgs,
} from '~/src/Domain/Shared/Http/HydraCollectionResponse'
import type GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'
import Throttle from '~/src/Domain/Shared/Utils/Throttle'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class ExportApiRepository implements ExportApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async findAll(user: UserIri): Promise<Export[]> {
    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<ExportProps>>(`${user.toString()}/exports`),
      Export.prototype.fromJSON,
    )

    return response.member
  }

  public async findCoaMappingExport(coaMapping: CoaMappingIri): Promise<Export | undefined> {
    return this.httpClient.post(`${coaMapping.toString()}/export_exists`)
  }

  public async createCoaMappingExport(coaMapping: CoaMappingIri): Promise<void> {
    await this.httpClient.post(`${coaMapping.toString()}/export`)
  }

  public async findDataRequestArchiveExport(dataRequest: DataRequestIri): Promise<Export | undefined> {
    return this.httpClient.post(`${dataRequest.toString()}/export_exists`, {
      dataRequest: dataRequest.toString(),
    })
  }

  public async createDataRequestArchiveExport(dataRequest: DataRequestIri): Promise<void> {
    await this.httpClient.post(`${dataRequest.toString()}/export`)
  }

  public async findDataRequestFileExport(file: FileIri): Promise<Export | undefined> {
    return this.httpClient.post(`${file.toString()}/export_exists`)
  }

  public async createDataRequestFileExport(file: FileIri): Promise<void> {
    await this.httpClient.post(`${file.toString()}/export`)
  }

  public async findTransactionalDataTableExport(
    iri: GenericIri,
    extension: string,
    fields: string[],
    url: string,
  ): Promise<Export | undefined> {
    const apiUrl = new URL(iri.toString(), this.httpClient.getBaseUrl())
    apiUrl.pathname = `${apiUrl.pathname.replace(/\/$/, '')}/exports/has_existing_transactional_data_table`

    return this.httpClient.post(apiUrl.toString(), {
      extension,
      fields,
      url,
    })
  }

  public async createTransactionalDataTableExport(
    iri: GenericIri,
    extension: 'csv' | 'xlsx',
    fields: string[],
    url: string,
  ): Promise<void> {
    const apiUrl = new URL(iri.toString(), this.httpClient.getBaseUrl())
    apiUrl.pathname = `${apiUrl.pathname.replace(/\/$/, '')}/exports/transactional_data_table`

    await this.httpClient.post(apiUrl.toString(), {
      extension,
      fields,
      url,
    })
  }

  public async findTransactionalDataPivotExport(
    iri: GenericIri,
    extension: string,
    rows: string[],
    columns: string[],
    values: { field: string, valueType: 'count' | 'sum' }[],
    url: string,
  ): Promise<Export | undefined> {
    const apiUrl = new URL(iri.toString(), this.httpClient.getBaseUrl())
    apiUrl.pathname = `${apiUrl.pathname.replace(/\/$/, '')}/exports/has_existing_transactional_data_pivot`

    return this.httpClient.post(apiUrl.toString(), {
      extension,
      rows,
      columns,
      values,
      url,
    })
  }

  public async createTransactionalDataPivotExport(
    iri: GenericIri,
    extension: 'csv' | 'xlsx',
    rows: string[],
    columns: string[],
    values: { field: string, valueType: 'count' | 'sum' }[],
    url: string,
  ): Promise<void> {
    const apiUrl = new URL(iri.toString(), this.httpClient.getBaseUrl())
    apiUrl.pathname = `${apiUrl.pathname.replace(/\/$/, '')}/exports/transactional_data_pivot`

    await this.httpClient.post(apiUrl.toString(), {
      extension,
      rows,
      columns,
      values,
      url,
    })
  }

  public async findTwoWayCorrelationsExport(
    phase: PhaseIri,
    fiscalYears: string[],
    businessUnitNames: string[],
    sourceNames: string[],
    primaryGlAccounts: string[],
    primaryAccountTypes: string[],
    primaryAccountSubtypes: string[],
    primaryDebitCreditIndicators: string[],
    secondaryGlAccounts: string[],
    secondaryAccountTypes: string[],
    secondaryAccountSubtypes: string[],
    secondaryDebitCreditIndicators: string[],
    extension: string,
    fields: string[],
    locale: string,
    url: string,
  ): Promise<Export | undefined> {
    return this.httpClient.post(
      `${phase.toString()}/exports/has_existing`,
      {
        fiscalYears,
        businessUnitNames,
        sourceNames,
        primaryGlAccounts,
        primaryAccountTypes,
        primaryAccountSubtypes,
        primaryDebitCreditIndicators,
        secondaryGlAccounts,
        secondaryAccountTypes,
        secondaryAccountSubtypes,
        secondaryDebitCreditIndicators,
        extension,
        fields,
        locale,
        url,
      },
    )
  }

  public async createTwoWayCorrelationsExport(
    phase: PhaseIri,
    fiscalYears: string[],
    businessUnitNames: string[],
    sourceNames: string[],
    primaryGlAccounts: string[],
    primaryAccountTypes: string[],
    primaryAccountSubtypes: string[],
    primaryDebitCreditIndicators: string[],
    secondaryGlAccounts: string[],
    secondaryAccountTypes: string[],
    secondaryAccountSubtypes: string[],
    secondaryDebitCreditIndicators: string[],
    extension: string,
    fields: string[],
    locale: string,
    url: string,
  ): Promise<void> {
    await this.httpClient.post(
      `${phase.toString()}/exports/create`,
      {
        fiscalYears,
        businessUnitNames,
        sourceNames,
        primaryGlAccounts,
        primaryAccountTypes,
        primaryAccountSubtypes,
        primaryDebitCreditIndicators,
        secondaryGlAccounts,
        secondaryAccountTypes,
        secondaryAccountSubtypes,
        secondaryDebitCreditIndicators,
        extension,
        fields,
        locale,
        url,
      },
    )
  }

  public async findOtherSideOfJournalEntriesExport(
    phase: PhaseIri,
    fiscalYears: string[],
    businessUnitNames: string[],
    sourceNames: string[],
    primaryGlAccounts: string[],
    primaryAccountTypes: string[],
    primaryAccountSubtypes: string[],
    primaryDebitCreditIndicators: string[],
    extension: string,
    fields: string[],
    locale: string,
    url: string,
  ): Promise<Export | undefined> {
    return this.httpClient.post(
      `${phase.toString()}/exports/has_existing`,
      {
        fiscalYears,
        businessUnitNames,
        sourceNames,
        primaryGlAccounts,
        primaryAccountTypes,
        primaryAccountSubtypes,
        primaryDebitCreditIndicators,
        extension,
        fields,
        locale,
        url,
      },
    )
  }

  public async createOtherSideOfJournalEntriesExport(
    phase: PhaseIri,
    fiscalYears: string[],
    businessUnitNames: string[],
    sourceNames: string[],
    primaryGlAccounts: string[],
    primaryAccountTypes: string[],
    primaryAccountSubtypes: string[],
    primaryDebitCreditIndicators: string[],
    extension: string,
    fields: string[],
    locale: string,
    url: string,
  ): Promise<void> {
    await this.httpClient.post(`${phase.toString()}/exports/create`, {
      fiscalYears,
      businessUnitNames,
      sourceNames,
      primaryGlAccounts,
      primaryAccountTypes,
      primaryAccountSubtypes,
      primaryDebitCreditIndicators,
      extension,
      fields,
      locale,
      url,
    })
  }

  public async findWorkProgramExport(
    workProgram: WorkProgramIri,
    title: string,
    description: string,
    steps: { stepSlug: string, title: string, description: string }[],
    url: string,
  ): Promise<Export | undefined> {
    return this.httpClient.post(`${workProgram.toString()}/export_exists`, {
      workProgram: workProgram.toString(),
      title,
      description,
      steps,
      url,
    })
  }

  public async createWorkProgramExport(
    workProgram: WorkProgramIri,
    title: string,
    description: string,
    steps: { stepSlug: string, title: string, description: string }[],
    url: string,
  ): Promise<void> {
    await this.httpClient.post(`${workProgram.toString()}/export`, {
      title,
      description,
      steps,
      url,
    })
  }

  public async createEngagementOverviewExport(): Promise<void> {
    await this.httpClient.post(`/v1/engagements/exports/engagement_overview`)
  }

  public async createMyEngagementExport(organisation: OrganisationIri): Promise<void> {
    await this.httpClient.post(`${organisation.toString()}/engagements/exports`)
  }

  public async download(preparedExport: Export): Promise<void> {
    const download = Throttle(async () => {
      const url = new URL(`${preparedExport['@id'].toString()}/download`, this.httpClient.getBaseUrl())
      const response = await fetch(url.toString(), {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip',
        },
      })

      saveAs(await response.blob(), preparedExport.fileNameDetails.fileName)
    }, 1000)

    await download()
  }
}
