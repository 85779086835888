/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import HydraCollectionResponse, {
  type HydraCollectionResponseArgs,
} from '~/src/Domain/Shared/Http/HydraCollectionResponse'
import type GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import WorkProgram, { type WorkProgramProps } from '~/src/Domain/WorkProgram/WorkProgram'
import type WorkProgramApiRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramApiRepositoryInterface'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class WorkProgramApiRepository implements WorkProgramApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async findStarted(engagement: EngagementIri): Promise<WorkProgram[]> {
    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<WorkProgramProps>>(
        `${engagement.toString()}/work_programs`,
      ),
      WorkProgram.prototype.fromJSON,
    )

    return response.member
  }

  public async startWorkProgram(
    phase: PhaseIri,
    slug: Slug,
    description: string | undefined,
    steps: { slug: Slug, hidden: boolean, hiddenReason: undefined | string }[],
    parameters: { name: string, value: string }[],
  ): Promise<void> {
    await this.httpClient.post(`${phase.toString()}/work_programs`, {
      slug: slug.toString(),
      description,
      steps: steps.map((s) => ({ ...s, slug: s.slug.toString() })),
      parameters,
    })
  }

  public async changeWorkProgram(
    workProgram: WorkProgramIri,
    description: string | undefined,
    steps: { slug: Slug, hidden: boolean, hiddenReason: string | undefined }[],
    parameters: { name: string, value: string | string[] | number | number[] }[],
  ): Promise<void> {
    await this.httpClient.patch(workProgram.toString(), {
      description,
      steps: steps.map((s) => ({
        slug: s.slug.toString(),
        hidden: s.hidden,
        hiddenReason: s.hiddenReason,
      })),
      parameters,
    })
  }

  public async markDataTableStepResult(
    workProgram: WorkProgramIri,
    slug: Slug,
    iri: GenericIri,
    filterValues: {
      field: string
      filterClass: FilterClass
      value: number | number[] | string | string[] | boolean
    }[],
    fields: string[],
  ): Promise<void> {
    const url = `${workProgram.toString()}/data_table_result`

    await this.httpClient.patch(url, {
      slug: slug.toString(),
      iri: iri.toString(),
      filterValues,
      fields,
    })
  }

  public async markDataPivotStepResult(
    workProgram: WorkProgramIri,
    slug: Slug,
    iri: GenericIri,
    filterValues: {
      field: string
      filterClass: FilterClass
      value: number | number[] | string | string[] | boolean
    }[],
    rows: string[],
    columns: string[],
    values: { field: string, valueType: 'count' | 'sum' }[],
  ): Promise<void> {
    const url = `${workProgram.toString()}/data_pivot_result`

    await this.httpClient.patch(url, {
      slug: slug.toString(),
      iri: iri.toString(),
      filterValues,
      rows,
      columns,
      values,
    })
  }

  public async finishWorkProgram(workProgram: WorkProgramIri): Promise<void> {
    await this.httpClient.patch(`${workProgram.toString()}/finish`)
  }

  public async reopenWorkProgram(workProgram: WorkProgramIri): Promise<void> {
    await this.httpClient.patch(`${workProgram.toString()}/reopen`)
  }

  public async duplicateWorkProgram(workProgram: WorkProgramIri): Promise<void> {
    await this.httpClient.patch(`${workProgram.toString()}/duplicate`)
  }

  public async deleteWorkProgram(workProgram: WorkProgramIri): Promise<void> {
    await this.httpClient.delete(workProgram.toString())
  }
}
