/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import CoaMapping, { type CoaMappingProps } from '~/src/Domain/CoaMapping/CoaMapping'
import type CoaMappingApiRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingApiRepositoryInterface'
import CoaMappingDTO, { type CoaMappingDTOProps } from '~/src/Domain/CoaMapping/CoaMappingDTO'
import CoaMappingDTOCollection from '~/src/Domain/CoaMapping/CoaMappingDTOCollection'
import type CoaMappingIri from '~/src/Domain/CoaMapping/CoaMappingIri'
import type { CoaMappingType } from '~/src/Domain/CoaMapping/CoaMappingType'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import HydraCollectionResponse, {
  type HydraCollectionResponseArgs,
} from '~/src/Domain/Shared/Http/HydraCollectionResponse'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class CoaMappingApiRepository implements CoaMappingApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async findAll(engagement: EngagementIri): Promise<CoaMapping[]> {
    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<CoaMappingProps>>(
        `${engagement.toString()}/coa_mappings`,
      ),
      CoaMapping.prototype.fromJSON,
    )

    return response.member
  }

  public async create(phase: PhaseIri, type: CoaMappingType): Promise<void> {
    await this.httpClient.post(`${phase.toString()}/coa_mappings`, {
      type,
    })
  }

  public async getMapping(coaMapping: CoaMappingIri): Promise<CoaMappingDTOCollection> {
    const mappingDtoProps = await this.httpClient.get<CoaMappingDTOProps[]>(
      `${coaMapping.toString()}/mappings`,
    )

    return new CoaMappingDTOCollection(
      mappingDtoProps.map((m) => CoaMappingDTO.prototype.fromJSON(m)),
    )
  }

  public async updateMapping(
    coaMapping: CoaMappingIri,
    mapping: {
      id: string
      accountType: string | undefined
      accountSubtype: string | undefined
      category: string | undefined
    }[],
  ): Promise<void> {
    await this.httpClient.patch(`${coaMapping.toString()}/mappings`, {
      mappings: mapping,
    })
  }

  public async fulfill(coaMapping: CoaMappingIri): Promise<void> {
    await this.httpClient.patch(`${coaMapping.toString()}/fulfill`)
  }

  public async acceptWithErrors(coaMapping: CoaMappingIri): Promise<void> {
    await this.httpClient.patch(`${coaMapping.toString()}/accept`)
  }
}
