import validate from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ysoah3fqaksetjhk3bs53psi7m/node_modules/nuxt/dist/pages/runtime/validate.js";
import _02_45auth_45global from "/home/node/app/src/UserInterface/App/middleware/02.auth.global.ts";
import _03_45i18n_45global from "/home/node/app/src/UserInterface/App/middleware/03.i18n.global.ts";
import _04_45state_45global from "/home/node/app/src/UserInterface/App/middleware/04.state.global.ts";
import manifest_45route_45rule from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__ysoah3fqaksetjhk3bs53psi7m/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _02_45auth_45global,
  _03_45i18n_45global,
  _04_45state_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "work-program": () => import("/home/node/app/src/UserInterface/WorkProgram/middleware/workProgram.ts"),
  organisation: () => import("/home/node/app/src/UserInterface/Organisation/middleware/organisation.ts"),
  "auth-initiated": () => import("/home/node/app/src/UserInterface/Identity/middleware/authInitiated.ts"),
  "profile-settings-previous-route": () => import("/home/node/app/src/UserInterface/Identity/middleware/profileSettingsPreviousRoute.ts"),
  "general-ledger": () => import("/home/node/app/src/UserInterface/GeneralLedger/middleware/generalLedger.ts"),
  engagement: () => import("/home/node/app/src/UserInterface/Engagement/middleware/engagement.ts"),
  "settings-previous-route": () => import("/home/node/app/src/UserInterface/Engagement/middleware/settingsPreviousRoute.ts"),
  "data-request": () => import("/home/node/app/src/UserInterface/DataRequest/middleware/dataRequest.ts"),
  "data-request-auth": () => import("/home/node/app/src/UserInterface/DataRequest/middleware/dataRequestAuth.ts"),
  "data-request-auth-initiated": () => import("/home/node/app/src/UserInterface/DataRequest/middleware/dataRequestAuthInitiated.ts"),
  "data-request-must-not-be-finished": () => import("/home/node/app/src/UserInterface/DataRequest/middleware/dataRequestMustNotBeFinished.ts"),
  bank: () => import("/home/node/app/src/UserInterface/Bank/middleware/bank.ts"),
  "accounts-receivable": () => import("/home/node/app/src/UserInterface/AccountsReceivable/middleware/accountsReceivable.ts"),
  "accounts-payable": () => import("/home/node/app/src/UserInterface/AccountsPayable/middleware/accountsPayable.ts"),
  admin: () => import("/home/node/app/src/UserInterface/App/middleware/admin.ts"),
  landing: () => import("/home/node/app/src/UserInterface/App/middleware/landing.ts"),
  "workflow-previous-route": () => import("/home/node/app/src/UserInterface/App/middleware/workflowPreviousRoute.ts")
}